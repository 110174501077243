<template>
  <v-container >
    <v-card width="500" height="auto" class="mx-auto pa-1">
      <v-layout style="height: auto;">
        <v-form style="width: 100% ">
          <v-card class="mx-auto pa-2" style="margin-left: 5px " outlined elevation="3">
            <v-layout style="height: 50px; width: 350px;">
              <v-text-field dense outlined class="user-id" style="height: 45px; width: 200px; margin: 5px"
                id="join_ID" 
                ref="join_ID" 
                label="등록 ID✽" 
                v-model="join_ID"
              />
              <v-btn class="rounded elevation-7 confirm_btn ma-2" style="height: 35px; width: 100px;" 
                  :loading="loadingID"
                  id="checkID" 
                  ref="checkID" 
                  @click="checkID()"
                >
                  ID사용확인
              </v-btn>
            </v-layout>
            <v-text-field dense outlined style="height: 45px; width: 225px; margin: 5px"
              prepend-inner-icon="mdi-lock" 
              :type="showNewPassword ? 'text' : 'password'" 
              :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
              label="비밀번호"
              ref="password" 
              v-model="password"
              @click:append="showNewPassword = !showNewPassword"
            />
            <v-text-field  dense outlined style="height: 45px; width: 225px; margin: 5px"
              prepend-inner-icon="mdi-lock" 
              :type="showConfirmPassword ? 'text' : 'password'" 
              :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
              label="비밀번호 확인"
              ref="rpassword" 
              v-model="rpassword"
              @click:append="showConfirmPassword = !showConfirmPassword"
            />
            <v-text-field dense outlined style="height: 45px; margin: 5px"
              :loading = "loading"
              id="name" 
              ref="name" 
              label="성명✽" 
              v-model="name" 
            />
            <v-text-field dense outlined style="height: 50px; margin: 5px"
              id="phone" 
              ref="phone" 
              label="연락처✽" 
              placeholder="숫자만 입력"
              v-model="phone"
              @keyup="getPhoneMask(phone)"
            />
            <div style="height: 0px; margin-top: -10px">알림수신✽</div>
            <v-radio-group dense outlined row 
              id="call_YN" 
              v-model="call_YN"
            >
                <v-radio ref="call_y" label="동의" value="Y" color="green" ></v-radio>
                <v-radio label="거부" value="N" color="red"></v-radio>
            </v-radio-group>
            <div style="height: 0px; margin-top: -5px">사용자 유형(권한)✽ </div>
            <v-radio-group dense outlined row 
              id="user_type" 
              v-model="user_type"
            >
              <v-radio ref="user_type" label="시스템 관리자" value="UT03" color="red" ></v-radio>
              <v-radio label="현장 관리자" value="UT05" color="blue"></v-radio>
              <v-radio label="조회자" value="UT08" color="green"></v-radio>
            </v-radio-group>
            <v-combobox dense outlined class="ma-1" style="height: 45px;" 
              id="duty" 
              ref="duty"
              label="직책" 
              placeholder="항목이 없으면 직접 입력하세요"
             :items="['대표','이사','본부장','농장/사업장장','부장','과장','대리','사원','기타']"
              v-model="duty"
              clearable 
            />
            <v-layout style="height: 45px; margin-top: -10px; margin: 5px; ">
              <v-text-field dense outlined style="height: 45px; margin-top: 0px; " 
                id="email_id" 
                ref="email_id" 
                label="이메일" 
                v-model="email_id" 
              />
              @
              <v-combobox dense outlined style="height: 45px; margin-top: 12px margin-right:50px" 
                id="email_host" 
                ref="email_host" 
                :items="['gmail.com','hotmail.com','naver.com','daum.net',]"
                placeholder="항목이 없으면 직접 입력하세요"
                v-model="email_host" 
              />
            </v-layout>
            <v-layout style="height: 45px; width: 150px; margin-top: 5px; margin-left: 5px; margin-right: 5px">
              <v-menu
                v-model="menuJoindate"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field dense outlined 
                    id="joindate" 
                    ref="joindate" 
                    v-model="joindate"
                    label="입사일✽"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  no-title locale="ko-KR"
                  v-model="joindate"
                  @input="menuJoindate = false"
                ></v-date-picker>
              </v-menu>
            </v-layout>
            <v-textarea outlined dense rows="2" style="height: auto; margin: 5px"
                label="전달사항"
                id="history" 
                ref="history" 
                v-model="history"
                value=""
                persistent-hint hint="소속사 와 등록 후 권한 설정자가 참조할 사항을 입력합니다"
            ></v-textarea>
            <v-card-actions class="d-block" >
              <v-row justify= "center" >
                <v-spacer></v-spacer>
                <v-btn class="rounded-pill elevation-7 normal-btn" style="height: 30px; width: 100px; margin: 10px;" 
                  :loading="loading"
                  id="save" 
                  ref="save" 
                  type="submit" 
                  @click.prevent="save()"
                >
                  저장
                </v-btn>
                <v-btn class="rounded-pill elevation-7 new-btn" style="height: 30px; width: 100px; margin: 10px;" 
                  id="cancel" 
                  ref="cancel" 
                  @click="cancel()">
                  닫기
                </v-btn>
                <v-spacer ></v-spacer>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-form>    
      </v-layout>
    </v-card>
  </v-container>
</template>

<script>
import Apis from '@/api/apis'
// import dateUtil from "@/utils/custom/dateProperty.js";
import Common from "@/utils/custom/common.js";

export default {
  name: 'JoinInfo',

  data: () => ({
    checkConfirm_ID: false,
    password: "",
    rpassword: "",
    showNewPassword: false,
    showConfirmPassword: false,
    
    join_ID: "",
    name: "",
    phone: "",
    call_YN: "",
    user_type: "",
    duty: "",
    email_id: "",
    email_host: "",
    joindate: "",
    history: "",
    
    menuJoindate: false,
    loadingID: false,
    loading: false,
  }),

  mounted() {
    this.$refs.join_ID.focus();
  },

  methods: {
    clearData() {
      this.checkConfirm_ID = false;
      this.password = "";
      this.rpassword = "";
      this.showNewPassword = false;
      this.showConfirmPassword = false;
    
      this.join_ID = "";
      this.name = "";
      this.phone = "";
      this.call_YN = "";
      this.user_type = "";
      this.duty = "";
      this.email_id = "";
      this.email_host = "";
      this.joindate = "";
      this.history = "";

    }, 
    checkData() {
      // console.log("combo data", this.bottom  && this.bottom.code || "empty");
      if (!this.join_ID) {
        this.$refs.join_ID.focus();
        return "사용 ID 를 입력하세요";
      }
      if (!this.checkConfirm_ID) {
        this.$refs.join_ID.focus();
        return "등록 가능한 ID인지 먼저 확인하세요";
      }

      let pw = this.password.trim();
      let rpw = this.rpassword.trim();
      if (!pw) {
        this.$refs.password.focus();
        return "비밀번호를 입력해주세요.";
      }
      if (!rpw) {
        this.$refs.rpassword.focus();
        return "새 비밀번호를 한번 더 입력해주세요.";
      }
      if ( pw !== rpw ) {
        this.$refs.password.focus();
        return "새 비밀번호와 확인이 다릅니다.";
      }
      if (!this.name.trim()) {
        this.$refs.name.focus();
        return "성명을 입력하세요";
      }
      if (!this.phone.trim()) {
        this.$refs.phone.focus();
        return "연락처를 입력하세요";
      }
      if (!this.call_YN) {
        this.$refs.call_y.$el.children[1].focus()
        return "알림 동의 여부를 선택하세요";
      }
      if (!this.user_type) {
        this.$refs.user_type.$el.children[1].focus()
        return "사용자 유형 선택 설정하세요";
      }
      if (!this.joindate) {
        this.$refs.joindate.focus();
        return "등록일을 선택 입력하세요";
      }
      return "";
    },
    checkID() {
      this.checkConfirm_ID = false;
      this.$store.commit("resMessage","");
      this.loadingID = true;
      Apis.accountCheck({
        session_id: "KOR///////",
        account: this.join_ID.trim(), 
        },(res) => {  
          if (res.result) {
            this.checkConfirm_ID = true;
            this.loadingID = false;
            this.$refs.join_ID.focus();
            this.$store.commit("resMessage",res.message);
          } else {
            alert(res.message);
            this.$refs.join_ID.focus();
            this.loadingID = false;
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("API 호출 오류",err)
            this.$refs.join_ID.focus();
            this.loadingID = false;
            alert(err);
        }
      ); 
    },
    save() {
      this.$store.commit("resMessage","");
      let msgCheckdata = this.checkData(); 
      if (msgCheckdata){
        this.$store.commit("resMessage",msgCheckdata);
        return;
      }
      this.loadingSave = true;
      Apis.joinUser({
        session_id: "KOR///////",
        account:	this.join_ID.trim(),
        user_name : this.name.trim(),
        password:	 this.password.trim(),
        grade:	this.user_type,
        belongcompany_cd:	"",
        duty:	this.duty,
        email1:	this.email_id.trim() + "@" + this.email_host,
        email2:	"",
        phone1:	this.phone.replace(/[^0-9]/g, ''),
        phone2:	"",
        call_yn: this.call_YN,
        joindate:	this.joindate,
        deldate:	"",
        history: this.history.trim(),
        etc1: "",
        etc2:	"",
        etc3: "",
        },(res) => {  
          if (res.result) {
            this.$store.commit("resMessage",res.message);
            this.loading = false;
            this.clearData();
            alert(this.name + " 님의 등록을 환영합니다. \n 소속사의 시스템 관리자에게 권한을 요청하십시요. \n 권한 설정을 통보 받으면 정상적으로 사용하실 수 있습니다.");
            this.$router.push({name: "Login"});
          } else {
            this.loadingSave = false;
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            this.loadingSave = false;
            console.log("API 호출 오류",err)
            alert(err);
        }
      )
    },
    cancel() {
      this.$router.push({
        name: "Login",
      });
    },
    getPhoneMask(val) {
      this.phone = Common.getMask(val)
    },

  }  // -----------methods 

}
// 01-06
</script>

<style lang="scss" >
.user-id .v-text-field--outlined  fieldset {
   color: black!important;
}
</style>
<style lang="scss" scoped>
.normal-btn{
  color:white !important;
  font-size:12pt !important;
  font-weight: bold !important;
  background-color: #3a4f3f !important;
}
.new-btn{
  color:#3a4f3f !important;
  font-size:12pt !important;
  font-weight: bold !important;
  background-color: rgb(248, 248, 248) !important;
}
.confirm_btn{
  color:white !important;
  font-size:12pt !important;
  font-weight: bold !important;
  background-color: #88191a !important;
}
</style>
